/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

//Custom JS
(function($) {
 $(document).ready(function(){

                                $('#main-button').click(function(){
                                      

                                      if($("#main-button").hasClass("open")){
                                        $("#main-nav").fadeOut("fast");
                                       // $("#main-button").removeClass("active");
    
                                    }
                                    else{ 
                                        // $("#main-button").addClass("active");
                                        $("#main-nav").fadeIn("fast").css("display", "table");
                                    }

                                    $(this).toggleClass('open');
                                });

                                var stickyNavTop = $('#main-header').offset().top;
 
                                var stickyNav = function(){
                                var scrollTop = $(window).scrollTop();
                                      
                                    if (scrollTop > stickyNavTop) { 

                                          $(".intro").addClass('sticky');
                                          //$("#main-button").addClass('stickyButton');
                                          $("#main-button").addClass('stickyButton');

                                    } else {
                                        $('.intro').removeClass('sticky');
                                        //$("#main-button").removeClass('stickyButton');
                                        $("#main-button").removeClass('stickyButton');
                                    }
                                };
                                 
                                stickyNav();
                                 
                                $(window).scroll(function() {
                                        stickyNav();
                                });


                                //adds protection to meta sliders
                                if($("body").find("metaslider")){
                                  $(".main-work .protect").insertBefore("ul.slides");
                                }


                           /* <?php
                                if(is_home()) { ?>

                                    $(".cat-item-all").addClass("current-cat");

                            <?php   }?>*/


                                //load_ta();
                                
                                
                            });

            

})(jQuery);

